var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.wrappedItem.show)?_c('v-layout',{attrs:{"column":""}},[_c('div',[_c('strong',[_vm._v(" Press Sheet ")]),(_vm.wrappedItem.sourceUrl)?_c('a',{staticClass:"info--text",attrs:{"href":_vm.wrappedItem.sourceUrl,"target":"_blank"}},[_vm._v(" #"+_vm._s(_vm.wrappedItem.id)+" ")]):[_vm._v(" "+_vm._s(_vm.wrappedItem.id)+" ")]],2),_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.dateLabel)+" ")]),_vm._v(" "+_vm._s(_vm.formatDisplayDate(_vm.wrappedItem.date))+" ")]),_c('div',[_c('strong',[_vm._v(" Machine: ")]),(_vm.wrappedItem.machine)?_c('router-link',{staticClass:"info--text",attrs:{"to":{
        name: 'profitMachines',
        query: {
          suffix: _vm.suffix,
          pageNum: '1',
          name: _vm.wrappedItem.machine,
        },
      },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.wrappedItem.machine)+" ")]):[_vm._v(" - ")]],2)]):_c('v-layout',{attrs:{"justify-center":""}},[_vm._v(" "+_vm._s(_vm.wrappedItem.details || ("No " + _vm.type + " press sheet"))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }