<template>
  <v-layout
    class="my-2"
    column
  >
    <v-divider class="mb-4" />

    <v-layout>
      <dsb-press-sheet-main-info
        :item="item"
        :show-state="showOrderItems"
        @show:state:change="showOrderItems = !showOrderItems"
      />

      <v-divider
        class="mx-3"
        vertical
      />

      <v-layout
        v-if="!item.pressSheets || !item.pressSheets.length"
        style="min-width: 300px; width: 40%;"
        align-center
        column
      >
        <strong>
          No press sheets
        </strong>
      </v-layout>
      <v-layout
        v-else
        style="min-width: 300px; width: 40%;"
        column
      >
        <v-layout
          v-for="(ps, i) in (item.pressSheets || [])"
          :key="genGUID(ps)"
          column
        >
          <v-layout>
            <v-flex sm6>
              <dsb-press-sheet-container
                :item="ps"
                type="created"
              />
            </v-flex>

            <v-flex sm6>
              <dsb-press-sheet-container
                :item="ps"
                type="distributed"
              />
            </v-flex>
          </v-layout>

          <v-divider
            v-if="i < item.pressSheets.length - 1"
            class="my-2"
          />
        </v-layout>
      </v-layout>

      <v-divider
        class="mx-3"
        vertical
      />

      <dsb-press-sheet-rule :item="item.filter || {}" />

      <dsb-press-sheet-actions :item="item" />
    </v-layout>

    <v-layout v-if="showOrderItems">
      <dsb-press-sheet-order-items :items="(item.orderItems || [])" />
    </v-layout>
  </v-layout>
</template>

<script>
import { formatDisplayDate, genGUID } from '@helpers';

import DsbPressSheetMainInfo from './_mainInfo.vue';
import DsbPressSheetContainer from './_pressSheet.vue';
import DsbPressSheetRule from './_rule.vue';
import DsbPressSheetActions from './_actions.vue';
import DsbPressSheetOrderItems from './_orderItems.vue';

export default {
  name: 'DsbPressSheet',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  components: {
    DsbPressSheetMainInfo,
    DsbPressSheetContainer,
    DsbPressSheetRule,
    DsbPressSheetActions,
    DsbPressSheetOrderItems,
  },

  data() {
    return {
      showOrderItems: false,
    };
  },

  methods: {
    formatDisplayDate,
    genGUID,
  },
};
</script>
