var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"page-size":_vm.items.length},scopedSlots:_vm._u([{key:"truckDate",fn:function(ref){
var _dateForFormatting = ref.item.truckDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}},{key:"rush",fn:function(ref){
var item = ref.item;
return [_c('dsb-state-icon',{attrs:{"state":item.rush}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
        name: 'orderItem',
        query: {
          suffix: _vm.suffix,
        },
        params: {
          id: item.id,
        },
      },"target":"_blank","color":"info","small":"","icon":""}},[_c('v-icon',[_vm._v(" remove_red_eye ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }