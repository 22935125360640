<template>
  <v-layout
    style="min-width: 10px; width: 5%;"
    align-center
  >
    <v-tooltip
      v-if="item.filter.sliceId"
      top
    >
      <template #activator="{ on }">
        <v-btn
          :to="{
            name: 'ruleSlice',
            query: {
              suffix,
            },
            params: {
              id: item.filter.sliceId,
            },
          }"
          target="_blank"
          color="info"
          class="mr-2"
          small
          icon
          v-on="on"
        >
          <v-icon>
            insert_drive_file
          </v-icon>
        </v-btn>
      </template>
      Show rule slice
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          :to="{
            name: 'messagesOutputMessages',
            query: {
              suffix,
              pageNum: '1',
              basketId: item.id,
            },
          }"
          target="_blank"
          color="info"
          small
          icon
          v-on="on"
        >
          <v-icon>
            local_post_office
          </v-icon>
        </v-btn>
      </template>
      Show related messages
    </v-tooltip>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';

import { AppLocationsNamespace } from '@store/types';

export default {
  name: 'DsbPressSheetActions',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
  },
};
</script>
