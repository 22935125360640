var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticStyle:{"min-width":"10px","width":"5%"},attrs:{"align-center":""}},[(_vm.item.filter.sliceId)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"to":{
          name: 'ruleSlice',
          query: {
            suffix: _vm.suffix,
          },
          params: {
            id: _vm.item.filter.sliceId,
          },
        },"target":"_blank","color":"info","small":"","icon":""}},on),[_c('v-icon',[_vm._v(" insert_drive_file ")])],1)]}}],null,false,4160695795)},[_vm._v(" Show rule slice ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
          name: 'messagesOutputMessages',
          query: {
            suffix: _vm.suffix,
            pageNum: '1',
            basketId: _vm.item.id,
          },
        },"target":"_blank","color":"info","small":"","icon":""}},on),[_c('v-icon',[_vm._v(" local_post_office ")])],1)]}}])},[_vm._v(" Show related messages ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }