<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <dsb-press-sheet
        v-for="ps in pressSheets"
        :key="genGUID(ps)"
        :item="ps"
      />

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
        class="mt-2"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { genGUID } from '@helpers';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import {
  namespace as PressSheetsNamespace,
  ActionTypes as PressSheetsActionTypes,
} from '../store.types';

import DsbPressSheet from '../_components/DsbPressSheet';

const dataLoadingOptions = {
  getterName: PressSheetsActionTypes.GET_PRESS_SHEETS,
  contentName: 'pressSheets',
};
const paginationOptions = { contentName: 'pressSheets' };
const searchOptions = {
  initialSearchDataFields: [
    'orderId', 'orderItemId', 'pressSheetId', 'basketId', 'ruleId',
  ],
};

export default {
  name: 'DsbPressSheets',

  components: {
    DsbPressSheet,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  computed: {
    ...mapState(PressSheetsNamespace, ['pressSheets']),

    searchFieldsConfig() {
      return [
        {
          value: 'orderId',
          label: 'Order ID',
          type: 'text',
          flex: 'sm6',
        },
        {
          value: 'orderItemId',
          label: 'Order Item ID',
          type: 'text',
          flex: 'sm6',
        },
        {
          value: 'basketId',
          label: 'Job ID',
          type: 'text',
          flex: 'sm4',
        },
        {
          value: 'pressSheetId',
          label: 'Press Sheet ID',
          type: 'text',
          flex: 'sm4',
        },
        {
          value: 'ruleId',
          label: 'Rule ID',
          type: 'text',
          flex: 'sm4',
        },
      ];
    },
  },

  methods: {
    ...mapActions(PressSheetsNamespace, [PressSheetsActionTypes.GET_PRESS_SHEETS]),

    genGUID,
  },
};
</script>
