<template>
  <v-layout
    style="min-width: 200px; width: 20%;"
    justify-space-between
    align-center
  >
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          :disabled="!(item.orderItems || []).length"
          icon
          small
          class="ml-2 mr-8"
          v-on="on"
          @click="$emit('show:state:change')"
        >
          <v-icon>
            {{ showState ? 'remove' : 'add' }}
          </v-icon>
        </v-btn>
      </template>
      {{ showState ? 'Hide' : 'Show' }} order items
    </v-tooltip>

    <v-layout column>
      <strong>
        Job #{{ item.id }}
      </strong>

      <div>
        Created at {{ formatDisplayDate(item.created) }}
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import { formatDisplayDate } from '@helpers';

export default {
  name: 'DsbPressSheetMainInfo',

  props: {
    item: {
      type: Object,
      required: true,
    },
    showState: Boolean,
  },

  methods: {
    formatDisplayDate,
  },
};
</script>
