<template>
  <v-layout
    style="min-width: 150px; width: 15%;"
    column
  >
    <div>
      <strong>
        Rule
      </strong>
      <router-link
        :to="{
          name: hadAdminRole ? 'ruleEdit' : 'ruleDetails',
          query: {
            suffix,
          },
          params: {
            id: item.id,
          },
        }"
        class="info--text"
        target="_blank"
      >
        #{{ item.id }}
      </router-link>
    </div>

    <div>
      <strong>
        Product Type:
      </strong>
      {{ item.productType || '-' }}
    </div>

    <div>
      <strong>
        Substrate:
      </strong>
      {{ item.substrate || '-' }}
    </div>

    <div>
      <strong>
        QTY:
      </strong>
      {{ item.minQuantity }} - {{ item.maxQuantity }}
    </div>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { AuthNamespace, AppLocationsNamespace } from '@store/types';

export default {
  name: 'DsbPressSheetRule',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),
  },
};
</script>
