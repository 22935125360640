<template>
  <v-layout
    v-if="wrappedItem.show"
    column
  >
    <div>
      <strong>
        Press Sheet
      </strong>
      <a
        v-if="wrappedItem.sourceUrl"
        :href="wrappedItem.sourceUrl"
        class="info--text"
        target="_blank"
      >
        #{{ wrappedItem.id }}
      </a>
      <template v-else>
        {{ wrappedItem.id }}
      </template>
    </div>

    <div>
      <strong>
        {{ dateLabel }}
      </strong>
      {{ formatDisplayDate(wrappedItem.date) }}
    </div>

    <div>
      <strong>
        Machine:
      </strong>
      <router-link
        v-if="wrappedItem.machine"
        :to="{
          name: 'profitMachines',
          query: {
            suffix,
            pageNum: '1',
            name: wrappedItem.machine,
          },
        }"
        class="info--text"
        target="_blank"
      >
        {{ wrappedItem.machine }}
      </router-link>
      <template v-else>
        -
      </template>
    </div>
  </v-layout>
  <v-layout
    justify-center
    v-else
  >
    {{ wrappedItem.details || `No ${type} press sheet` }}
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';

import { formatDisplayDate } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

const getDateField = (type) => {
  const types = { created: 'createDate', distributed: 'distributeDate' };

  return types[type] || '';
};

const getDetailsField = (type) => {
  const types = { created: 'createDetails', distributed: 'distributeDetails' };

  return types[type] || '';
};

export default {
  name: 'DsbPressSheetContainer',

  props: {
    item: {
      type: Object,
      required: true,
    },
    // created, distributed
    type: {
      type: String,
      default: 'created',
    },
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    wrappedItem() {
      return {
        id: this.item.id,
        machine: this.item.machine,
        sourceUrl: this.item.sourceUrl,
        show: this.item[this.type],
        date: this.item[getDateField(this.type)],
        details: this.item[getDetailsField(this.type)],
      };
    },

    dateLabel() {
      switch (this.type) {
        case 'created':
          return 'Created at';
        case 'distributed':
          return 'Distributed at';
        default:
          return 'Date:';
      }
    },
  },

  methods: {
    formatDisplayDate,
  },
};
</script>
