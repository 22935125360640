var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticStyle:{"min-width":"150px","width":"15%"},attrs:{"column":""}},[_c('div',[_c('strong',[_vm._v(" Rule ")]),_c('router-link',{staticClass:"info--text",attrs:{"to":{
        name: _vm.hadAdminRole ? 'ruleEdit' : 'ruleDetails',
        query: {
          suffix: _vm.suffix,
        },
        params: {
          id: _vm.item.id,
        },
      },"target":"_blank"}},[_vm._v(" #"+_vm._s(_vm.item.id)+" ")])],1),_c('div',[_c('strong',[_vm._v(" Product Type: ")]),_vm._v(" "+_vm._s(_vm.item.productType || '-')+" ")]),_c('div',[_c('strong',[_vm._v(" Substrate: ")]),_vm._v(" "+_vm._s(_vm.item.substrate || '-')+" ")]),_c('div',[_c('strong',[_vm._v(" QTY: ")]),_vm._v(" "+_vm._s(_vm.item.minQuantity)+" - "+_vm._s(_vm.item.maxQuantity)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }