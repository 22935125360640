<template>
  <vbt-table
    :headers="headers"
    :items="items"
    :page-size="items.length"
  >
    <template #truckDate="{ item: { truckDate: _dateForFormatting } }">
      {{ formatDisplayDate(_dateForFormatting) }}
    </template>

    <template #rush="{ item }">
      <dsb-state-icon :state="item.rush" />
    </template>

    <template #actions="{ item }">
      <v-btn
        :to="{
          name: 'orderItem',
          query: {
            suffix,
          },
          params: {
            id: item.id,
          },
        }"
        target="_blank"
        color="info"
        small
        icon
      >
        <v-icon>
          remove_red_eye
        </v-icon>
      </v-btn>
    </template>
  </vbt-table>
</template>

<script>
import { mapState } from 'vuex';

import { formatDisplayDate } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

const headers = Object.freeze([
  { text: 'ID', value: 'id' },
  { text: 'Order ID', value: 'orderId' },
  { text: 'Order Item ID', value: 'orderItemId' },
  { text: 'Product type', value: 'productType' },
  { text: 'Truck Date', value: 'truckDate' },
  { text: 'Rush', value: 'rush' },
  { text: '', value: 'actions' },
]);

export default {
  name: 'DsbPressSheetOrderItems',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
  },

  methods: {
    formatDisplayDate,
  },
};
</script>
